import React from 'react'
import { PageProps } from 'gatsby'

import { GlobeAltIcon, LightningBoltIcon, MailIcon, ScaleIcon } from '@heroicons/react/outline'
import ServiceDetails from '../components/ServiceDetails'
import Footer from '../components/Footer'
import Header from '../components/Header'

const leftFeatureItems = [
  {
    id: 1,
    name: 'Video Conferencing ',
    description:
      `Make every meeting count and reduce operational costs. 

      We work closely with our partners to offer bespoke video conferencing solutions for your business to help create efficient meeting areas to reach your teams and clients with good quality video conferencing equipment to help meet your business goals
      `,
    icon: GlobeAltIcon,
  },
  {
    id: 2,
    name: 'CCTV / Surveillance',
    description:
    `We work with trusted partners to provide  effective CCTV Design & Installation security solutions that are seamlessly integrated to your IT infrastructure.`,
    icon: LightningBoltIcon,
  },
]
const rightFeatureItems = [
  {
    id: 1,
    name: 'Servers and Storage',
    description:
    `Our secure onsite and cloud-based servers and storage range is designed to be as flexible and customisable as possible - we help you accomplish what you need to achieve with specialist advice so you can choose the solution that suits you best.`,
    icon: ScaleIcon,
  },
  {
    id: 2,
    name: 'Photocopiers',
    description:
    `We  provide Office Photocopier & Multifunction Printer Leasing & maintenance services to a wide range of financial institutions in The City from banks, insurance and fintech companies. We work with trusted partners to provide reliable, durable and energy efficient machines for single or multisite purposes.`,
    icon: MailIcon,
  },
]

const IctMaintenance = (props: PageProps) => {
  return (
    <div className="bg-white">

      <Header path={props.path} />

      <main>
        <div className="py-12 bg-white overflow-hidden lg:py-12">
          <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
            <svg
              className="hidden lg:block absolute left-full transform -translate-x-1/2 -translate-y-1/4"
              width={404}
              height={784}
              fill="none"
              viewBox="0 0 404 784"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={404} height={784} fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)" />
            </svg>

            <div className="relative">
              <h2 className="text-center text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                ICT MAINTENANCE
              </h2>
              <p className="mt-4 max-w-3xl mx-auto text-center text-xl text-gray-500">
                Server or systems installations, Upgrades to existing systems, Migrations from old to new or upgraded systems Virtualisation implementations, Hosted, Cloud, Hybrid or On-premises solutions, Telephony, communications and broadband, Servers, Desktops, Mobility, Peripherals [printers, switches, wireless, devices], Network and infrastructure.
              </p>
            </div>

            <ServiceDetails leftFeatures={leftFeatureItems} rightFeatures={rightFeatureItems} />

            
          </div>
        </div>
      </main>
    
      <Footer />
    </div>
  )
}

export default IctMaintenance